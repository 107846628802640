import { pick, FlowHttpRequestOptions, mixHeadersOptions } from '@snfe/flow-yapi-helpers';
import * as model from '@/model';
import http from '../http';

/**
 * @desc id=1460809, catid=251183, projectId=41427, created=2024-08-26 10:31:10, last-modified=2024-08-26 19:38:43
 * @see [Yapi接口mock地址](http://yapi.sankuai.com/mock/41427/adminApi/user/ban)
 * @see [Yapi接口地址](http://yapi.sankuai.com/project/41427/interface/api/1460809)
 */
export function postAdminApiUserBan(params: model.adminApi.IPostAdminApiUserBanParameter, options?: FlowHttpRequestOptions) {
  return http.post<model.adminApi.IPostAdminApiUserBanResponse>('/adminApi/user/ban', {
    data: pick(params, ['id']),
    ...mixHeadersOptions(options, { 'Content-Type': 'application/json' }),
  });
}
