<template>
  <mtd-modal v-model="value" :keyboard="keyboard">
    <!-- todo, 改order排序？ -->
    <div class="initial-model">
      <div :class="isCurRoute">AGI-Eval</div>
      <div class="info-content">
        <div v-if="modalContent.bigHeadline" class="bigHeadline" :style="modalContent.bigHeadlineStyle" v-html="modalContent.bigHeadline" />
        <div v-if="twoStep" class="progress-bar-box">
          <progress-bar class="progress-bar" :progress="100" :text="false" :animate="true" />
          <div class="progress-text">100%</div>
        </div>
        <div
          v-if="modalContent.smallHeadline"
          class="smallHeadline"
          :style="modalContent.smallHeadlineStyle"
          v-html="modalContent.smallHeadline"
        />
        <div
          v-if="modalContent.markednessHeadline"
          class="markednessHeadline"
          :style="modalContent.markednessHeadlineStyle"
          v-html="modalContent.markednessHeadline"
        />
        <div
          v-if="modalContent.imgUrl"
          class="imgUrl"
          :style="{
            ...modalContent.imgUrlStyle,
            backgroundImage: `url(${modalContent.imgUrl})`,
          }"
        >
          <!-- <img class="imgUrl" :src="modalContent.imgUrl" alt="QAQ图片不见了"/> -->
        </div>
        <div v-if="twoStep" class="pagingDevice">
          <span class="active"/>
          <span/>
        </div>
        <div
          v-if="modalContent.bigImgUrl"
          class="bigImgUrl"
          :style="{
            ...modalContent.bigImgUrlStyle,
            backgroundImage: `url(${modalContent.bigImgUrl})`,
          }"
        >
          <!-- <img class="bigImgUrl" :src="modalContent.bigImgUrl" alt="QAQ图片不见了"/> -->
        </div>
        <div
          v-for="bigHeadline in modalContent.bigHeadlineList"
          :key="bigHeadline"
          class="bigHeadline"
          :style="modalContent.bigHeadlineStyle"
          v-html="bigHeadline"
        />
        <div v-if="modalContent.widthImgUrl" :style="modalContent.widthImgUrlStyle">
          <img class="widthImgUrl" :src="modalContent.widthImgUrl" alt="" />
        </div>
        <div
          v-for="promptText in modalContent.promptTextList"
          :key="promptText"
          class="promptText"
          :style="modalContent.promptTextStyle"
          v-html="promptText"
        />
        <div v-if="modalContent.lightText" class="lightText" :style="modalContent.lightTextStyle">
          <span class="lightTextText">{{ modalContent.lightText.text }}</span>
          <mtd-popover trigger="hover" placement="right">
            <template #content>
              <div v-html="modalContent.lightText.hint"/>
            </template>
            <span style="cursor: pointer" v-html="modalContent.lightText.icon" />
          </mtd-popover>
        </div>
        <div
          v-if="modalContent.bigHeadlineList?.length && modalContent.bigHeadlineList[0] === '去解锁新的模型吧！'"
          class="pagingDevice pagingDeviceBtm"
        >
          <span/>
          <span class="active"/>
        </div>
        <slot name="btn" />
        <slot name="tail" />
      </div>
    </div>
  </mtd-modal>
</template>

<script lang="ts">
import ProgressBar from '@/components/ProgressBar.vue';
import { Component, Model, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {
    ProgressBar,
  },
})
export default class AgiModal extends Vue {
  @Model('value') value!: boolean;
  @Prop({ default: false }) keyboard?: boolean;
  @Prop({ default: () => ({}) }) modalContent!: any;
  @Prop({ default: false }) twoStep?: boolean;

  get isCurRoute() {
    if (this.$route.name === 'mvp-home' || this.$route.name === 'mvp-login') {
      return 'mvp-info-symbol';
    }

    return 'info-symbol';
  }
}
</script>

<style scoped lang="scss">
@keyframes glow {
  from {
    text-shadow: 0 0 2px #fff, 0 0 7px #ffe178b5, 0 0 10px #ffe178b5;
  }
  to {
    text-shadow: 0 0 2px #fff, 0 0 7px #ffe178b5, 0 0 10px #ffe178b5;
  }
}
::v-deep {
  .mtd-modal-content-wrapper {
    padding: 0 !important;
  }
  .mtd-modal-close {
    display: none !important;
  }
}
.initial-model {
  min-width: 550px;
  max-width: 660px;
  min-height: 400px;
  max-height: 790px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  background: #000000e5;
  color: #fff;
  padding: 40px 30px 60px;
  .pagingDevice {
    widows: 100%;
    transform: translateX(10px);
    > span {
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #ccc;
      margin-right: 5px;
      &.active {
        background: #ffd43b;
      }
    }
  }
  .pagingDeviceBtm {
    transform: translate(10px, 14px);
  }
  .progress-bar-box {
    width: 60%;
  }
  .progress-bar {
    width: 100%;
    margin: 10px 0;
  }
  .progress-text {
    width: 100%;
    color: #ffd43b;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }
  .lightTextText {
    font-family: meituan type;
    font-size: 18px;
    font-weight: 400;
    text-align: left;
    white-space: nowrap;
    color: #ffffff;
    animation: glow 1.5s ease-in-out infinite alternate;
  }
  .info-symbol {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 900;
    line-height: 48.76px;
    text-align: left;
    color: #ffd43b;
    margin-bottom: 30px;
  }
  .mvp-info-symbol {
    font-family: Montserrat;
    font-size: 40px;
    font-weight: 900;
    line-height: 48.76px;
    text-align: left;
    color: #4c00ff;
    margin-bottom: 30px;
  }
  .info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    .bigHeadline {
      font-family: meituan type;
      font-size: 32px;
      font-weight: 700;
      line-height: 44.8px;
      text-align: left;
      margin-bottom: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .smallHeadline {
      font-family: PingFang SC;
      font-size: 24px;
      font-weight: 400;
      line-height: 33.6px;
      text-align: left;
      margin-bottom: 10px;
    }
    .markednessHeadline {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      text-align: center;
      color: #ffd43b;
      margin-bottom: 10px;
    }
    .widthImgUrl {
      width: 280px;
    }
    .imgUrl {
      margin: 20px 0;
      width: 230px;
      height: 230px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .bigImgUrl {
      margin: 20px 0;
      width: 230px;
      height: 230px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
    }
    .promptText {
      font-family: PingFang SC;
      font-size: 20px;
      font-weight: 400;
      line-height: 30px;
      text-align: center;
      color: #ffffffb2;
    }
  }
}
</style>
