<template>
  <div>
    <community-top-nav />
    <div class="community-layout">
      <router-view />
    </div>
    <AgiModal v-model="isUnPC" :modalContent="isUnPCCon">
      <template #btn>
        <div style="margin: 30px 0 10px">
          <mtd-button class="btn" @click="() => (isUnPC = false)">好的</mtd-button>
        </div>
      </template>
    </AgiModal>
    <CommunityBottom v-if="!isCurRoute('mvp-home') && !isCurRoute('mvp-top-Ranking')"/>
    <Feedback/>
  </div>
</template>

<script lang="ts">
import AgiModal from '@/components/AgiModal.vue';
import CommunityBottom from '@/components/CommunityBottom.vue';
import CommunityTopNav from '@/components/CommunityTopNav.vue';
import { Component, Vue } from '@/decorators';
import $router from '@/router/index';
import { isPC } from '@/utils/import-all';
import Feedback from './ranking/feedback/index.vue';
@Component({
  components: {
    AgiModal,
    CommunityTopNav,
    CommunityBottom,
    Feedback,
  },
})
export default class CommunityLayout extends Vue {
  get route() {
    return this.$route.name;
  }

  isUnPC = false;
  isUnPCCon = {
    bigHeadline: '<div>手机端适配中</div><div>更多体验移步pc端</div>',
    bigImgUrl: 'https://p0.meituan.net/llmarena/5fc91621ad2229330963418007fb7ff134140.png',
  };

  isCurRoute(target) {
    return this.$route.name === target;
  }

  isUnPcChange() {
    this.isUnPC = true;
  }

  mounted() {
    if (!isPC()) this.isUnPcChange();
  }

  beforeRouteEnter(to, form, next) {
    const routerList = ['/mvp/home', '/mvp/login'];
    if (!isPC() && !routerList.includes(to.path)) {
      if (location.pathname.includes('/mvp/home')) {
        next((vm) => {
          console.log(vm);
        });
      }

      $router.push(to.path === '/mvp/login' ? '/mvp/login' : '/mvp/home');
    }

    next((vm) => {
      !isPC() && location.pathname.includes('/mvp/login') && vm.isUnPcChange && vm.isUnPcChange();
    });
  }
}
</script>

<style lang="scss" scoped>
.community-layout {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  min-width:1280px;
  .main {
    width: 100%;
    min-width: 1280px;
    flex: 1 1 auto;
  }
  .background-img {
    position: absolute;
    top: 0;
    width: 1500px;
    height: 850px;
    top: 150px;
    left: 30%;
  }

}

.mtd-btn {
  background-color:  #4c00ff;
  color: white;
}
.btn {
  width: 130px;
  border-radius: 1000px;
  height: 46px;
  line-height: 46px;
  transform: translateX(5px);
  font-family: meituan type;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin-right: 10px;
}
</style>
