import Layout from '@/views/arena/Layout.vue';
const keepAlive = false;
/*
 * 如需使用动态import的分包特性，请注意优化分包规则和加载优化，避免打包出过多零散文件包
 * ps: 内网项目体积较小的情况下不太建议使用分包，分包过多反而容易打满浏览器并发连接数限制
 * webpackChunkName: 分包名,相同名称的包会被打包在一起,建议按分模块分包
 * webpackPrefetch: 页面加载完成后预取
 * webpackPreload: 页面加载时预加载
 */
// const List = () => import(/* webpackChunkName: 'List' */ /* webpackPrefetch :true */ /* webpackPreload :true */ '@/views/application/List.vue');

export default {
  path: '/llmArena',
  component: Layout,
  redirect: '/llmArena/home',
  meta: {
    title: '首页',
  },
  children: [
    {
      path: '/llmArena/home',
      name: 'community-llmArena-home',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/arena/Home.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '首页',
        cid: 'c_techportal_v89rn38y',
        keepAlive,
      },
    },
    {
      path: '/llmArena/login',
      name: 'community-llmArena-login',
      component: () => import(/* webpackChunkName: 'Basic' */ '@/views/arena/login/Login.vue'),
      meta: {
        icon: 'mtdicon mtdicon-home-o',
        title: '登录',
        keepAlive,
        cid: 'c_techportal_a098mlc9',
      },
    },
  ],
};
