<template>
  <transition name="fade">
    <div v-if="visible" :class="['message', type]" @click="close">
      <img v-if="type === 'success'" src="https://p0.meituan.net/llmarena/0d0b7ea56413341ffefec2dc25b3b01f932.png" alt=""/>
      <img v-if="type === 'error'" src="https://p0.meituan.net/llmarena/9e4a64e36598002fb16032e2bfcc3331782.png" alt=""/>
      <p>{{ message }}</p>
    </div>
  </transition>
</template>

<script lang="ts">
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class Message extends Vue {
  visible = false;
  message = '';
  type = 'success';
  timeout: number | null = null;

  show(options: any) {
    this.message = typeof options === 'string' ? options : options.message;
    this.type = options.type || 'success';
    this.visible = true;
    if (this.timeout) clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.visible = false;
    }, 3000);
  }

  close() {
    this.visible = false;
  }
}
</script>

<style lang="less" scoped>
p {
  margin: 10px 0;
}
.message {
  position: fixed;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 20px;
  border-radius: 1000px;
  color: white;
  z-index: 1111;
  cursor: pointer;
  transition: opacity 0.3s;
  display: flex;
  align-items: center;
  // min-height: 53.6px;
  img {
    flex-shrink: 0;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.message.success {
  background: #DDFFE9E5;
  border: 3px solid #35C4BB;
  color: #0EAAA0;
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}
.message.error {
  background: #FFE9DD;
  border: 3px solid #FF9A24;
  color: #FF8A00;
  font-family: PingFang SC;
  font-size: 24px;
  font-weight: 500;
  line-height: 33.6px;
  text-align: left;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
