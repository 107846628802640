<template>
  <div v-track="$route.meta?.cid" id="agi-app">
    <div class="layout">
      <img
        :style="`filter: blur(${route === 'home' ? '3px' : '10px'})`"
        class="background-img"
        src="https://p0.meituan.net/llmarena/2b88cdc20cab12be9a0897e0abefedb61458025.png"
      />
      <div class="main"><router-view /></div>
    </div>
    <top-nav v-if="!route?.includes('login')" />
    <AgiModal v-model="isUnPC" class="unPc" :modalContent="isUnPCCon">
      <template #btn>
        <div style="margin: 30px 0 10px">
          <mtd-button class="btn" @click="() => (isUnPC = false)">好的</mtd-button>
        </div>
      </template>
    </AgiModal>
  </div>
</template>

<script lang="ts">
import AgiModal from '@/components/AgiModal.vue';
import TopNav from '@/components/TopNav.vue';
import { Component, Vue } from '@/decorators';
import $router from '@/router/index';
import { isPC } from '@/utils/import-all';

@Component({
  components: {
    TopNav,
    AgiModal,
  },
})
export default class Layout extends Vue {
  get route() {
    return this.$route.name;
  }

  isUnPC = false;
  isUnPCCon = {
    bigHeadline: '<div>手机端适配中</div><div>更多体验移步pc端</div>',
    bigImgUrl: 'https://p0.meituan.net/llmarena/86b6ac12ea125f84163504ed7ceaf36c19129.png',
  };

  isUnPcChange() {
    this.isUnPC = true;
  }

  mounted() {
    if (!isPC()) this.isUnPcChange();
  }

  beforeRouteEnter(to, from, next) {
    const routerList = ['/llmArena/home', '/llmArena/login'];
    if (!isPC() && !routerList.includes(to.path)) {
      if (location.pathname.includes('/llmArena/home')) {
        next();
      }

      $router.push(to.path === '/llmArena/login' ? '/llmArena/login' : '/llmArena/home');
    }

    next((vm) => {
      !isPC() && location.pathname.includes('/llmArena/home') && vm.isUnPcChange && vm.isUnPcChange();
    });
  }
}
</script>

<style>
#agi-app {
  width: 100%;
  height: 100%;
  overflow: hidden;
  min-width: 1280px;
}
.unPc .initial-model {
  top: 100px;
  transform: translate(-50%, 0);
}
</style>

<style lang="scss" scoped>
.btn {
  width: 130px;
  border-radius: 1000px;
  height: 46px;
  line-height: 46px;
  transform: translateX(5px);
  font-family: meituan type;
  font-size: 22px;
  font-weight: 400;
  text-align: center;
  margin-right: 10px;
  &.ccc {
    background: #757575;
    color: #fff;
  }
}
.layout {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  background-color: rgb(88, 189, 251);
  .main {
    width: 100%;
    min-width: 1280px;
    flex: 1 1 auto;
  }
  .background-img {
    position: absolute;
    top: 0;
    width: 1500px;
    height: 850px;
    top: 150px;
    left: 30%;
  }
}
</style>
