<template>
  <div ref="track" v-track="'c_techportal_66wjvrus'" class="community-mvp w-full">
    <div :class="`community-top-nav ${isCurRoute('mvp-home') ? 'home-nav' : 'other-nav'}`">
      <div class="router">
        <img :src="imageSrc" alt="" class="icon" @click="jump('mvp-home')"/>
        <div style="cursor: pointer" class="title" @click="jump('mvp-home')">AGI-Eval</div>
        <!--  -->
        <div
          v-for="router in routerButtonMeta"
          :key="router.title"
          :class="{ 'router-button': true, active: isCurRoute(router.target) }"
          @click="jump(router.target, router.title)"
        >
          {{ router.title }}
        </div>
        <div class="router-button  data-studio" @click="goDataStudio('https://ai-ceping.com/')">
          Data Studio
          <img :src="dataImageSrc" alt="" class="data-studio-img" @click="goDataStudio('https://ai-ceping.com/')"/>
        </div>
      </div>
      <i class="mtdicon mtdicon-avatar-fill avatar" @click="goPersonalCenter()" />
    </div>
  </div>
</template>
<script lang="ts">
import { postUserMe } from '@/api/modules/user';
import { Component, Vue, Ref } from 'vue-property-decorator';
import { track } from '@/utils/import-all';
@Component
export default class App extends Vue {
  @Ref('track') trackRef: any;
  routerButtonMeta = [
    {
      title: '评测榜单',
      target: 'mvp-top-Ranking',
    },
    {
      title: '人机比赛',
      target: 'community-llmArena-home',
    },
    {
      title: '评测集社区',
      target: 'evaluation-home',
    },
  ];

  get imageSrc() {
    return this.isCurRoute('mvp-home')
      ? 'https://p0.meituan.net/llmarena/5fc91621ad2229330963418007fb7ff134140.png'
      : 'https://p0.meituan.net/llmarena/312bf2d7ad4b59e48f94f046ab17560a32914.png';
  }

  get dataImageSrc() {
    return this.isCurRoute('mvp-home')
      ? 'https://p0.meituan.net/llmarena/a794a1c704f7d79a38d337483481e1ac314.png'
      : 'https://p0.meituan.net/llmarena/91dacc5251fc478ebf4a9648b0e48a56298.png';
  }

  goDataStudio(url) {
    track(this.trackRef, 'b_techportal_80urjonw_mc');
    window.open(url);
  }

  jump(target, name) {
    if (name === '人机比赛') {
      track(this.trackRef, 'b_techportal_o4eqfd3w_mc');
      localStorage.setItem('agi-eval-jump', 'community-top-nav');
      window.open(
        this.$router.resolve({
          name: target,
        }).href,
        '_blank'
      );
    } else {
      this.$route.name !== target && this.$router.push({ name: target });
    }
  }
  isCurRoute(target) {
    return this.$route.name === target;
  }
  async goPersonalCenter() {
    try {
      await postUserMe({});
      this.$router.push({ name: 'mvp-personal-center' });
    } catch (error) {
      this.$route.name !== 'mvp-login' && this.$router.push({ name: 'mvp-login' });
    }
  }
}
</script>
<style lang="scss" scoped>
.community-mvp {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 10;
  min-width: 1280px;
}
.community-top-nav {
  display: flex;
  top: 0;
  height: 60px;
  width: 100%;
  padding: 0 40px;
  align-items: center;
  justify-content: space-between;
}
.home-nav {
  color: white;
  background-color: #000;
  line-height: 36px;
  white-space: nowrap;
  .router{
    &-button:hover {
      background-color:  #4c00ff;
      line-height: 36px;
    }
    .icon {
      width:30px;
      height:30px;
      cursor: pointer;
    }
    .title {
      font-size: 24px;
      margin-left:10px;
    }
  }
}
.other-nav {
  background-color: #fff;
  line-height: 36px;
  white-space: nowrap;
  .router {
    &-button:hover {
      background-color: #EBECF1;
      line-height: 36px;
    }
    .active {
      color: #4c00ff;
      line-height: 36px;
    }
    .icon {
      width:30px;
      height:30px;
      cursor: pointer;
    }
    .title {
      font-size: 24px;
      margin-left:10px;
    }
  }
}
.router {
  display: flex;
  align-items: center;
  &-button {
    height: 36px;
    margin-left: 30px;
    font-size: 18px;
    line-height: 36px;
    border-radius: 20px;
    padding: 0 20px;
    cursor: pointer;
    white-space: nowrap;
  }
}
.avatar {
  cursor: pointer;
  font-size: 30px;
}

.data-studio {
  display: flex;
  align-items: center;
  .data-studio-img {
    width:15px;
    height:15px;
    cursor: pointer;
    margin-left: 5px;
  }
}

</style>
