import { factory } from '@snfe/flow-http';
// import sso from config directly would cause circular dependency
import { postUserLogout } from '@/api/modules/user';
import Vue from 'vue';
export const baseURL = '';
const login = process.env.NODE_ENV === 'production' ? '' : '/community';

const getLoginUrl = () => {
  if (location.pathname.includes('llmArena')) {
    return `${login}/llmArena/login`;
  }

  return `${login}/mvp/login`;
};

const validateResponse = (data) => {
  if (data?.rescode === 0) {
    return true;
  }

  return false;
};

const isUnauthorized = (res) => {
  if (res.config.url === '/webApi/user/me') {
    localStorage.setItem('user', JSON.stringify(res?.data?.data?.user || {}));
  } else if (!res.config.url.includes('/webApi/user/')) {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    if (JSON.stringify(user) === '{}' && !location.pathname.includes('login') && !location.pathname.includes('home')) {
      postUserLogout({});
    }
  }

  if (location.pathname.includes('login')) return false;

  return res.data.rescode === 4030;
};

const http = factory({
  baseURL,
  notifyMessage: (data) => Vue.prototype.$agitd.message.error(data.message),
  loginUrl: getLoginUrl(),
  validateResponse,
  isUnauthorized,
  rethrowError: true,
});

export default http;
