import { postInstanceEvalChat, postInstanceEvalChatLogFeedback } from '@/api/modules/instanceEval';
import { Api, ApiAction, Spectra, SpectraModule } from '@/decorators';

@Spectra({ namespaced: true, name: 'ModelChat' })
export default class extends SpectraModule {
  // 先直接当仓库使了- -，再调这个接口重写一下吧～
  @Api({ defaultState: { chatLogList: [] } })
  chatLogList = postInstanceEvalChat;
  @ApiAction()
  chatFeedBack = postInstanceEvalChatLogFeedback;
}
