import { importAll, ImportAllResult } from '@/utils';
import Vue from 'vue';
import VueRouter from 'vue-router';
import { agi, mvp } from './base';

const routeModules: ImportAllResult[] = importAll(require.context('@/router/modules', false, /\.ts$/));
const routes = routeModules.reduce((finalRoutes: Array<any>, routerModule) => finalRoutes.concat(routerModule.module), []);

const base = process.env.NODE_ENV === 'production' ? '' : '/community/';

const $router = new VueRouter({
  mode: 'history',
  base,
  routes: [
    {
      path: '/',
      redirect: '/mvp/home',
    },
    ...routes,
  ],
});
Vue.use(VueRouter);

$router.beforeEach((to: any, from, next) => {
  let favicon = mvp;
  document.title = 'AGI-Eval评测社区';
  if (to.path.includes('/llmArena')) {
    favicon = agi;
    document.title = 'AGI-Eval人机比赛';
  }

  const link: any = document.querySelector("link[rel*='icon']") || document.createElement('link');
  link.type = 'image/png';
  link.rel = 'icon';
  link.href = favicon;
  document.head.appendChild(link);

  next();
});

window.$router = $router;
export default $router;
